import type { BaseEntity } from '@app/models/base-entity';

export const DEFAULT_PRECISION = 2;
import type { ModuleName } from '@app/models/module-name';

export enum CalculationMethod {
  average = 'average',
  formula = 'formula',
  maximum = 'maximum',
  minimum = 'minimum',
  record_count = 'record_count',
  total = 'total',
}

export enum CalculationClass {
  instant = 'instant',
  stored = 'stored',
}

export enum EmptyHandlingType {
  not_available = 'not_available',
  zero = 'zero',
}

export enum RoundingBehaviour {
  default = 'default',
  round = 'round',
}

export enum VisualFormat {
  currency = 'currency',
  number = 'number',
  percent = 'percent',
}

export enum CalculationDataSource {
  formula = 'formula',
  related_records = 'related_records',
  sub_forms = 'sub_forms',
}

export type StoredInQuestionType = 'calculator' | 'calculation_text' | 'calculation_select';

export interface RecordCalculation extends BaseEntity {
  calculation_class: CalculationClass;
  calculation_method: CalculationMethod;
  can_recalculate?: boolean;
  connections?: RecordCalculation[];
  data: {
    formulas_used: string[];
    parsed_formula: string;
  };
  data_source: CalculationDataSource;
  formula: string;
  index: number;
  module_name: ModuleName;
  module_name_id: number;
  name: string;
  options: {
    stored_in_question_types?: StoredInQuestionType[];
    sub_form_states?: string[];
    value_empty_handling?: EmptyHandlingType;
    value_precision?: number;
    value_rounding_behaviour?: RoundingBehaviour;
    visibility?: boolean;
    visual_currency_symbol?: string;
    visual_format?: VisualFormat;
    visual_precision?: number;
    visual_rounding_behaviour?: RoundingBehaviour;
    visual_trailing_zeros?: boolean;
  };
  permission_check: boolean;
  recalculated_at: string | null;
  recalculating: boolean;
  sub_form_ids?: number[];
  sub_form_list_ids?: number[];
  sub_form_question_code?: string;
  variable_code: string;
}
