
  import DsCheckbox from '@app/components/ds-checkbox.vue';
  import type { StringBoolean } from '@app/utils/types/string-boolean';
  import DropdownSelect from './dropdown-select.vue';
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: { DsCheckbox, DropdownSelect } })
  export default class FilterCheckbox extends Vue {
    @Model('input', {}) readonly value?: boolean | StringBoolean;
    @Prop(Boolean) readonly stringBoolean?: boolean;
    @Prop(String) readonly label?: string;
  }
