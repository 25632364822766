
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class WithColorIndication extends Vue {
    @Prop(String) color?: string;
    @Prop(String) text?: string;

    get circleStyle(): Record<string, string> {
      if (this.color) {
        return {
          'border-left-color': this.color,
          'background-color': `${this.color}1A`,
        };
      }
      return {};
    }
  }
