
  import { Component, Vue, Model, Prop, Ref } from 'vue-property-decorator';
  import QrCode from '@chenfengyuan/vue-qrcode';
  import { qrCodeLink } from '@app/utils/qr-code-link';
  import DsModal from '@app/components/ds-modal.vue';

  @Component({ components: { DsModal, QrCode } })
  export default class QrCodeModal extends Vue {
    @Model('input') value!: boolean;
    @Prop(String) readonly data!: string;
    @Prop(String) readonly title?: string;
    @Ref() readonly linkInput: HTMLInputElement | undefined;

    get downloadQrCodeLink(): string {
      return qrCodeLink(this.data, {
        output_format: 'png',
        download: true,
      });
    }

    copyLinkToClipboard(): void {
      const input = this.linkInput;
      if (input) {
        input.focus();
        this.$copyText(this.data).finally(() => this.closeModal());
      }
    }

    closeModal(): void {
      this.$emit('input', false);
    }
  }
