import type { UserCollection } from '@app/models/user-collection';

import type { BaseEntity } from './base-entity';
import type { Attachment } from './attachment';

export enum AuditReportTemplateFormat {
  PDF = 'pdf',
  Word = 'word',
}

export enum AuditReportTemplateVisibility {
  Hidden = 'hidden',
  Visible = 'visible',
}

export enum AuditReportTemplateReportType {
  ModuleRecord = 'ModuleRecord',
  RecordIndex = 'RecordIndex',
  SubFormCompletion = 'SubFormCompletion',
}

export enum AuditReportRecordType {
  ModuleRecord = 'ModuleRecord',
  SubFormCompletion = 'SubFormCompletion',
}

export enum AuditReportTemplatePurviewType {
  all_records = 'all_records',
  record_index = 'record_index',
  single_record = 'single_record',
}

export interface BaseGenerationParams {
  async: boolean;
  record_type: AuditReportRecordType;
  report_format: AuditReportTemplateFormat;
}

export interface GenerateParams extends BaseGenerationParams {
  record_id: number | string;
}

export interface BulkGenerateParams extends BaseGenerationParams {
  record_ids: (number | string)[];
}

export interface GenerateJobResult extends BaseGenerationParams {
  job_id: string;
  message: string;
}

export interface AuditReportTemplate extends BaseEntity {
  attachment?: Attachment;
  filename?: string;
  module_name_id: number;
  name: string;
  purview: AuditReportTemplatePurviewType;
  report_format: AuditReportTemplateFormat;
  report_type: AuditReportTemplateReportType;
  restricted?: boolean;
  row_limit?: number;
  sub_form_list_id?: number;
  user_collection?: UserCollection;
  user_collection_id?: number;
  visibility: AuditReportTemplateVisibility;
}
