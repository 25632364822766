
  import { Component, Prop } from 'vue-property-decorator';
  import DateTimePicker from '@app/components/date-time-picker.vue';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import WithDateFilter from '@app/mixins/with-date-filter.vue';
  import { Collapse } from 'uiv';
  import YearPickerButton from '@app/components/year-picker-button.vue';

  import DatePicker from './date-picker.vue';

  @Component({ components: { DateTimePicker, Collapse, DatePicker, DsDropdown, YearPickerButton } })
  export default class DsDatetimeSelector extends WithDateFilter {
    @Prop(Boolean) readonly allowClear?: boolean;
    @Prop(Boolean) readonly disableDuration?: boolean;
    @Prop(Boolean) readonly disableByYear?: boolean;

    get options(): { [key: string]: string } {
      return {
        week: this.$t('app.labels.within_last_week'),
        today: this.$t('app.labels.today'),
        month: this.$t('app.labels.within_last_month'),
        year: this.$t('app.labels.within_last_year'),
      };
    }

    get label(): string {
      if (Array.isArray(this.value)) {
        if (this.isRangeAFullYear(this.startDate, this.endDate) && this.startDate?.getFullYear()) {
          return this.startDate?.getFullYear().toString();
        }
        const startLabel = this.value[0] || this.$t('app.labels.anytime');
        const endLabel = this.value[1] || this.$t('app.labels.anytime');
        return [startLabel, endLabel].join(' to ');
      }

      return this.value === 'all' || !this.value ? this.$t('app.labels.anytime') : this.options?.[this.value];
    }

    get showAllowClear() {
      return this.allowClear && !!this.localFilters && this.localFilters !== 'all';
    }

    selectOption(option: string) {
      const optionToEmit = option === 'all' ? undefined : option;
      this.$emit('input', optionToEmit);
    }

    toggle(): void {
      // TODO: restructure to avoid using timeout
      setTimeout(() => {
        this.dropdownVisible = !this.dropdownVisible;
      }, 1);
    }

    clear(): void {
      this.localFilters = 'all';
      this.yearDate = null; // clear year selector
      this.selectOption(this.localFilters);
    }
  }
