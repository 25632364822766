
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleRecord } from '@app/models/module-record';

  @Component
  export default class ModuleRecordLink extends Vue {
    @Prop({ type: [Number, String] }) readonly recordId?: number | string;

    record: ModuleRecord | null = null;

    get recordLink() {
      return `/module_records/${this.record?.id}`;
    }

    async beforeMount() {
      try {
        if (this.recordId) {
          this.record = await this.$api
            .getModuleRecord(Number(this.recordId), { only: ['id', 'title'] }, { cache: true, join: true })
            .then(({ data }) => data);
        }
      } catch {}
    }
  }
