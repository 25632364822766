export const downloadFile = (url: string, filename: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export interface DocumentTemplateFileReceivedForDownload {
  audit_report_template_id: number;
  downloadable_url?: string;
  error?: string;
  file_name?: string;
  job_id: string;
}
