
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import moment from 'moment';
  import { Component, Prop, PropSync } from 'vue-property-decorator';
  import { mixins } from 'vue-class-component';
  import WithSearchableDsDropdown from '@app/mixins/with-searchable-ds-dropdown';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import TextHighlight from 'vue-text-highlight';
  import Blocking from '@app/mixins/blocking';
  import bootbox from 'bootbox';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import DsIconText from '@app/components/ds-icon-text.vue';
  import type { AuditReportTemplate } from '@app/models/audit-report-template';
  import type { ModuleRecord } from '@app/models/module-record';
  import {
    AuditReportRecordType,
    AuditReportTemplatePurviewType,
    AuditReportTemplateReportType,
    AuditReportTemplateVisibility,
  } from '@app/models/audit-report-template';
  import type { DonesafeFilterOptions } from '@app/services/donesafe-api-utils';
  import type { ListManager } from '@app/services/list-manager/list-manager';
  import type { DocumentTemplateFileReceivedForDownload } from '@app/utils/download-file';
  import { downloadFile } from '@app/utils/download-file';
  import { toaster } from '@app/utils/toaster';
  import type { Subscription } from '@rails/actioncable';
  import consumer from '@app/channels/consumer';

  import { AUDIT_REPORTS_ONLY } from './models';
  import type { AuditReportTemplateOnly, LimitedModuleName } from './models';

  @Component({ components: { DsDropdown, TextHighlight, DsIconText } })
  export default class ModuleRecordsExportPanel extends mixins(WithSearchableDsDropdown, Blocking) {
    @Prop(Object) manager!: ListManager<ModuleRecord>;
    @Prop(Object) readonly moduleName!: LimitedModuleName;
    @PropSync('exportPanelVisible') syncedExportPanelVisible!: boolean;

    auditReportTemplates: Pick<AuditReportTemplate, AuditReportTemplateOnly>[] = [];
    dropdownOpen = false;
    notificationsSubscriptions: Record<string, Subscription> = {};

    get searchableAuditReportTemplates(): Pick<AuditReportTemplate, AuditReportTemplateOnly>[] {
      return this.getSearchableItems<Pick<AuditReportTemplate, AuditReportTemplateOnly>>(this.auditReportTemplates, 'name');
    }

    get exportToCsvText() {
      const base = this.$t('app.views.admin.module_records.export.to_csv', { count: this.exportCount });

      return this.currentUserStore.isTechAdmin && !this.indexExportEnabled ? this.$t('app.labels.hidden_name', { name: base }) : base;
    }

    get selectedCount() {
      return this.manager.selectedTo.length;
    }

    get rangeCount() {
      return this.manager.total;
    }

    get exportCount() {
      return this.selectedCount || this.rangeCount;
    }

    get canExportRecords() {
      return this.csvRights || !!this.auditReportTemplates.length;
    }

    get csvRights() {
      return this.indexExportEnabled || this.currentUserStore.isTechAdmin;
    }

    get indexExportEnabled(): boolean {
      return !!this.moduleName?.feature_set?.export_record_index_to_csv;
    }

    get showPanel() {
      return this.canExportRecords && this.syncedExportPanelVisible;
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get auditReportOnly(): (keyof AuditReportTemplate)[] {
      return this.currentUserStore.isTechAdmin ? AUDIT_REPORTS_ONLY : AUDIT_REPORTS_ONLY.filter((val) => val !== 'restricted');
    }

    get templateFilters(): DonesafeFilterOptions<AuditReportTemplate> {
      const baseFilters = {
        module_name_id: this.moduleName.id,
        report_type: AuditReportTemplateReportType.ModuleRecord,
        purview: [AuditReportTemplatePurviewType.all_records, AuditReportTemplatePurviewType.record_index],
      };

      return this.currentUserStore.isTechAdmin ? baseFilters : { ...baseFilters, restricted: false };
    }

    async fetchAllIds() {
      const { data: moduleRecords } = await this.$api.getModuleRecords(
        {
          filters: { ...this.manager.filters, ...this.manager.customFilters } as DonesafeFilterOptions<
            ModuleRecord,
            DonesafeModuleRecordExtraFilters
          >,
          only: ['id'],
          per_page: -1,
        },
        { cache: true }
      );
      return moduleRecords.map(({ id }) => id);
    }

    async selectAll() {
      this.blocking(async () => {
        if (!this.moduleName) return;
        const moduleRecordIds = await this.fetchAllIds();
        this.manager.setSelectedTo(moduleRecordIds);
      });
    }

    rowLimitReached(item: Pick<AuditReportTemplate, AuditReportTemplateOnly>): boolean {
      if (!item.row_limit) return false;
      return this.exportCount > item.row_limit;
    }

    rowLimitText(item: Pick<AuditReportTemplate, AuditReportTemplateOnly>): string {
      const limitOptions = { count: this.exportCount, limit: item.row_limit };
      const baseText = this.$t('app.views.admin.module_records.export.record_limit', limitOptions);
      if (this.rowLimitReached(item)) {
        return baseText + this.$t('app.views.admin.module_records.export.select_fewer_records');
      }

      return baseText;
    }

    exportName(item: Pick<AuditReportTemplate, AuditReportTemplateOnly>) {
      if (item.visibility === AuditReportTemplateVisibility.Hidden) return this.$t('app.labels.hidden_name', { name: item.name });
      if (item.restricted) return this.$t('app.labels.restricted_name', { name: item.name });

      return item.name;
    }

    clearAll() {
      this.manager.clearSelectedTo();
    }

    exportCsv() {
      const currentFilters = { ...this.manager.filters, ...this.manager.customFilters } as DonesafeFilterOptions<
        ModuleRecord,
        DonesafeModuleRecordExtraFilters
      >;
      const filters = this.manager.selectedTo.length ? { id: this.manager.selectedTo, module_name_id: this.moduleName.id } : currentFilters;

      bootbox.dialog({
        title: this.$t('components.export_confirm_modal.title'),
        message: this.$t('components.export_confirm_modal.message'),
        onEscape: true,
        backdrop: true,
        buttons: {
          download: {
            label: this.$t('components.export_confirm_modal.download_button'),
            className: 'btn-primary',
            callback: () =>
              this.$api
                .csvExport({ filters })
                .then(({ data }) => downloadFile(data.url, `${this.moduleName.plural_display}_${moment().format()}.csv`))
                .catch(({ data }) => toaster({ text: this.formatErrorMessage(data.error), icon: 'error' })),
          },
          email: {
            label: this.$t('components.export_confirm_modal.email_button'),
            className: 'btn-success',
            callback: () =>
              this.blocking(async () => {
                this.$api
                  .csvExportAsync({ filters })
                  .then(({ data }) => toaster(data.message))
                  .catch(({ data }) => toaster({ text: this.formatErrorMessage(data.error), icon: 'error' }));
              }),
          },
          cancel: { label: this.$t('app.buttons.cancel'), className: 'btn-default pull-left' },
        },
      });
    }

    formatErrorMessage(message: string): string {
      if (message.includes('undefined method')) {
        return this.$t('components.export_confirm_modal.template_error');
      }
      return message;
    }

    async exportToDocument(item: Pick<AuditReportTemplate, AuditReportTemplateOnly>) {
      const recordIds = this.selectedCount ? this.manager.selectedTo : await this.fetchAllIds();

      this.blocking(async () => {
        const { data } = await this.$api.bulkGenerateAuditReport(item.id, {
          record_ids: recordIds,
          record_type: AuditReportRecordType.ModuleRecord,
          report_format: item.report_format,
          async: true,
        });
        this.subscribeToNotifications(data.job_id);
        toaster(data.message);
      });
    }

    toggleExportPanel(): void {
      this.syncedExportPanelVisible = !this.syncedExportPanelVisible;
    }

    beforeMount() {
      this.$api
        .getAuditReportTemplates({
          filters: this.templateFilters,
          sort: 'index',
          only: this.auditReportOnly,
        })
        .then(({ data }) => {
          const visibleTemplates = data.filter((template) => template.visibility === AuditReportTemplateVisibility.Visible);
          this.auditReportTemplates = this.currentUserStore.isTechAdmin ? data : visibleTemplates;
        });
    }

    subscribeToNotifications(jobId: string): void {
      this.notificationsSubscriptions[jobId] = consumer.subscriptions.create(
        {
          channel: 'WebNotificationsChannel',
          record_type: 'AuditReportTemplateResult',
          job_id: jobId,
        },
        {
          received: ({ downloadable_url, file_name, job_id, error }: DocumentTemplateFileReceivedForDownload) => {
            if (downloadable_url && file_name) {
              this.notificationsSubscriptions[job_id]?.unsubscribe();
              delete this.notificationsSubscriptions[job_id];
              downloadFile(downloadable_url, file_name);
            } else {
              toaster({ text: error || this.$t('components.export_confirm_modal.export_failed'), icon: 'error' });
            }
          },
        }
      );
    }

    beforeDestroy(): void {
      Object.values(this.notificationsSubscriptions).forEach((subscription) => subscription.unsubscribe());
    }
  }
