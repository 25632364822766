
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({ components: {} })
  export default class FollowIcon extends Vue {
    @Prop(Boolean) readonly followed?: boolean;
    @Prop(String) readonly icon?: string;
    @Prop(String) readonly activeIcon?: string;

    get followIcon(): string {
      return this.icon || 'star_border';
    }

    get activeFollowIcon(): string {
      return this.activeIcon || 'star';
    }
  }
