
  import SubFormResponseOutput from '@app/components/sub-form-completion/sub-form-response-output.vue';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import type { ModuleRecord } from '@app/models/module-record';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { SubFormResponse } from '@app/models/sub-form-response';
  import { FieldType } from '@app/models/sub-form-question';

  const SKIP_QUESTION_TYPES = [FieldType.report, FieldType.video, FieldType.table_calculation];

  @Component({ components: { SubFormResponseOutput } })
  export default class ModuleRecordTableResponseOutput extends Vue {
    @Prop({ required: true, type: Object }) readonly moduleRecord!: ModuleRecord;
    @Prop(Array) readonly questions!: SubFormQuestion[];
    @Prop(Boolean) readonly asText?: boolean;
    @Prop(Boolean) readonly emptyFallback?: boolean;

    get primaryQuestion(): Maybe<SubFormQuestion> {
      if (this.response) {
        return this.questions.find((q) => q.id === this.response?.sub_form_question_id);
      }
    }

    get response(): Maybe<SubFormResponse> {
      const code = this.questions[0]?.code;
      if (code) {
        return this.questions
          .map((q) => this.moduleRecord.sub_form_completion?.sub_form_responses.find((r) => r.sub_form_question_id === q.id))
          .filter(Boolean)[0];
      }
    }

    get forcePrintValue(): boolean {
      return (
        !!this.asText ||
        (!!this.response?.sub_form_question_field_type && SKIP_QUESTION_TYPES.includes(this.response?.sub_form_question_field_type))
      );
    }
  }
