import { Vue, Component } from 'vue-property-decorator';
import { get } from 'lodash';

@Component
export default class WithSearchableDsDropdown extends Vue {
  searchQueries: Record<string, string> = {
    default: '',
  };

  extendsDefaultSearchable(newKey: string): void {
    this.searchQueries = {
      ...this.searchQueries,
      [newKey]: '',
    };
  }

  onDropdownSearch(newValue: string, searchableKey = 'default'): void {
    this.searchQueries = {
      ...this.searchQueries,
      [searchableKey]: newValue,
    };
  }

  getSearchableItems<T>(items: T[], path: string, searchableKey = 'default'): T[] {
    return items.filter((item: T) => {
      return `${get(item, path)}`.toLowerCase().includes(this.searchQueries[searchableKey].toLowerCase());
    });
  }
}
