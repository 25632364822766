
  import FilterCheckbox from '@app/components/filter-checkbox.vue';
  import { useCurrentUserStore } from '@app/stores/currentUser';
  import { useAccountStore } from '@app/stores/account';
  import DsDropdown from '@app/components/ds-dropdown.vue';
  import DsLabel from '@app/components/ds-label.vue';
  import ModuleRecordTableResponseOutput from '@app/components/module-record/module-record-table-response-output.vue';
  import ModuleRecordsListCell from '@app/components/module-record/module-records-list-cell.vue';
  import ModuleRecordQrCodeLinkModal from '@app/components/module-record/qr-code-modal.vue';
  import { MRT_MN_ONLY } from './models';
  import type { LimitedModuleName } from './models';
  import ModuleRecordsExportPanel from './module-records-export-panel.vue';
  import WithColorIndication from '@app/components/with-color-indication.vue';
  import { WRAP_TEXT_COLUMN_WIDTH, RELATED_RECORDS_PREFIX, RELATIONSHIP_PREFIX } from '@app/constants';
  import ModuleRecordModals from '@app/mixins/module-record-modals';
  import type { SelectOption } from '@app/models/question-options/shared';
  import type { DonesafeModuleRecordExtraFilters } from '@app/services/api/module-records-api';
  import type { ExtraUserApiOptions } from '@app/services/api/tenant-users-api';
  import { isFilterable, isSelect, isSortable } from '@app/services/model-helpers';
  import type { Subscription } from '@rails/actioncable';
  import type { AxiosPromise } from 'axios';
  import { map, debounce, flatten, intersection, isEqual, keyBy, mapValues, orderBy, uniq, uniqWith, groupBy } from 'lodash';
  import moment from 'moment';
  import { Tooltip } from 'uiv';
  import { mixins } from 'vue-class-component';
  import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
  import consumer from '../../channels/consumer';
  import { BaseTable, BaseTableCell } from '../base-table';
  import DropdownSelect from '../dropdown-select.vue';
  import EntitySelector from '../entity-selector.vue';
  import FilterSelect from '../filter-select.vue';
  // TODO: for future
  // import ScopeFilter from '../filters/scope-filter.vue';
  import LocationSelector from '../location/location-selector.vue';
  import OrganizationSelector from '../organization/organization-selector.vue';
  import RecordSelector from '../record-selector.vue';
  import Select2 from '../select2.vue';
  import UserSelector from '../user/user-selector.vue';
  import FollowIcon from '../follow-icon.vue';
  import BaseTableFieldCheckbox from '../base-table/base-table-field-checkbox.vue';
  import DsDatetimeSelector from '../ds-datetime-selector.vue';
  import type { Dictionary } from '@app/models/dictionary';
  import type { Indicator } from '@app/models/indicator';
  import type { Location } from '@app/models/location';
  import type { ModuleName, ModuleNameIndexOption } from '@app/models/module-name';
  import type { ModuleRecord, RecordAccess, RecordActionLink } from '@app/models/module-record';
  import type { Organization } from '@app/models/organization';
  import type { RecordRelation } from '@app/models/record-relation';
  import type { Relationship } from '@app/models/relationship';
  import type { ScoreBand } from '@app/models/score-band';
  import type { SubFormQuestion } from '@app/models/sub-form-question';
  import type { TenantUser } from '@app/models/tenant-user';
  import type { UserInvolvement } from '@app/models/user-involvement';
  import { CalculationClass, CalculationMethod } from '@app/models/record-calculation';
  import { CUSTOM_RESPONSE_TEMPLATE_TYPES, FieldType } from '@app/models/sub-form-question';
  import { RecordLinkBehaviour, BASE_FILTER_OPTIONS } from '@app/models/module-name';
  import type { MultiPersonSelectorQuestionOptions } from '@app/models/question-options/multi-person-selector-question-options';
  import type { SinglePersonSelectorQuestionOptions } from '@app/models/question-options/single-person-selector-question-options';
  import type { DonesafeApiOptions, DonesafeFilterOptions, OnlyOption, OnlyOptions } from '@app/services/donesafe-api-utils';
  import type { ListManagerFetchDataParams, ListManagerField, ListManagerSortItem } from '@app/services/list-manager/types';
  import { ListManager } from '@app/services/list-manager/list-manager';
  import { DatePickerUIOptions } from '@app/models/question-options/date-question-options';

  const timezoneOffset = moment().format('[GMT]Z');
  type UserFilter = DonesafeFilterOptions<TenantUser, ExtraUserApiOptions>;

  @Component({
    name: 'ModuleRecordsTable',
    components: {
      FilterCheckbox,
      ModuleRecordsListCell,
      WithColorIndication,
      ModuleRecordTableResponseOutput,
      RecordSelector,
      Select2,
      FilterSelect,
      OrganizationSelector,
      LocationSelector,
      UserSelector,
      EntitySelector,
      BaseTable,
      BaseTableCell,
      DsLabel,
      DropdownSelect,
      DsDropdown,
      ModuleRecordQrCodeLinkModal,
      Tooltip,
      FollowIcon,
      ModuleRecordsExportPanel,
      DsDatetimeSelector,
    },
  })
  export default class ModuleRecordsTable extends mixins(ModuleRecordModals) {
    @Prop(Boolean) readonly allowExport?: boolean;
    @Prop(String) readonly loaderPosition?: 'fixed' | 'absolute';
    @Ref() readonly table?: BaseTable<ModuleRecord>;

    @Prop(Boolean) allowCustomFilters?: boolean;
    @Prop(Object) customFilters?: DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>;
    @Prop(Boolean) disableSorting?: boolean;
    @Prop(Array) extraFields?: ListManagerField<ModuleRecord>[];
    @Prop(Object) filters?: DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>;
    @Prop(String) headerTitleClass?: string;
    @Prop(Array) indexOptions?: string[];
    @Prop(Boolean) linkFirstColumn?: boolean;
    @Prop(Array) only?: OnlyOptions<ModuleRecord>;
    @Prop(Number) pageSize?: number;
    @Prop(String) recordLinkBehaviour?: RecordLinkBehaviour;
    @Prop(Boolean) responsive?: boolean;
    @Prop(Boolean) reverse?: boolean;
    @Prop([Function, String]) rowClass?: string | ((item: ModuleRecord, index: number) => string);
    @Prop(String) sort?: string; // sort field without '-' prefix
    @Prop({ type: [Boolean, String] }) subscribe?: boolean | 'refresh' | 'update';
    @Prop(Boolean) subscribeIndexNotifications?: boolean;
    @Prop(String) tableHeight?: string;
    @Prop(Boolean) useHistory?: boolean;

    DatePickerUIOptions = DatePickerUIOptions;
    calculationsByRecordId: Record<number, Record<number, string>> = {};
    debounceFollowHash: Record<number, () => void> = {};
    exportPanelVisible = false;
    fromRecordRelations: RecordRelation[] = [];
    indexNotificationsSubscription: Nullable<Subscription> = null;
    manager: Nullable<ListManager<ModuleRecord>> = null;
    moduleName: Nullable<LimitedModuleName> = null;
    moduleNames: ModuleName[] = [];
    permissions: Dictionary<RecordAccess> = {};
    qrCodeModalVisible = false;
    qrCodeText = '';
    qrCodeTitle = '';
    relatedModules: ModuleName[] = [];
    relatedQuestions: SubFormQuestion[] = [];
    relationships: Relationship[] = [];
    scoreBands: ScoreBand[] = [];
    toRecordRelations: RecordRelation[] = [];
    updatesSubscription: Nullable<Subscription> = null;
    users: Dictionary<Pick<TenantUser, 'id' | 'full_name'>> = {};

    get accountStore() {
      return useAccountStore();
    }

    get allFilters(): DonesafeFilterOptions<ModuleRecord, { _do_not_fetch?: boolean }> {
      return { ...this.filters, ...this.customFilters };
    }

    get anyModulePublic(): boolean {
      return this.moduleNames.some((m) => m.feature_set?.is_public);
    }

    get apiOptions(): DonesafeApiOptions<ModuleRecord> {
      // TODO: not all the includes are required

      const excludePrintTypes = [...CUSTOM_RESPONSE_TEMPLATE_TYPES, FieldType.single_select, FieldType.radio, FieldType.button_select]
        .filter((c) => {
          // include first column field type as it is always rendered as text
          const questions = (this.fields[0] as ListManagerField<ModuleRecord> & { questions: SubFormQuestion[] }).questions as
            | undefined
            | SubFormQuestion[];
          if (questions) {
            return questions.some((q) => q.field_type !== c);
          }
          return true;
        })
        // video and report are ignored
        .filter((c) => [FieldType.video, FieldType.report].indexOf(c) < 0);
      const only = this.only || this.defaultOnlyOptions;
      return { only, exclude_print_value_for: excludePrintTypes };
    }

    get availableColumns(): ListManagerField<ModuleRecord>[] {
      return [
        { title: this.headerLabel('actions', this.$t('app.labels.actions')), name: 'actions' },
        {
          title: this.headerLabel('created_at', this.$t('app.labels.created_at_with_timezone', { timezone: timezoneOffset })),
          name: 'created_at',
          sortField: 'created_at',
        },
        {
          title: this.headerLabel('created_by', this.$t('app.labels.created_by')),
          name: 'created_by',
          sortField: 'created_by',
          filter: true,
        },
        { title: this.headerLabel('id', this.$t('app.labels.ID')), name: 'id', sortField: 'id' },
        { title: this.headerLabel('follow', this.$t('app.labels.follow')), name: 'follow', sortField: 'follow', filter: true },
        {
          title: this.headerLabel('follow_count', this.$t('app.labels.follow_count')),
          name: 'follow_count',
          sortField: 'follow_count',
          filter: true,
        },
        {
          title: this.headerLabel('module_name', this.$t('app.labels.module_name')),
          name: 'module_name',
          sortField: 'module_name',
          filter: true,
        },
        {
          title: this.headerLabel('followed_at', this.$t('app.labels.followed_at')),
          name: 'followed_at',
          sortField: 'followed_at',
          filter: true,
        },
        {
          title: this.headerLabel('location', this.$t('app.labels.location')),
          name: 'location',
          sortField: 'location',
          filter: true,
        },
        {
          title: this.headerLabel('organization', this.$t('app.labels.organization')),
          name: 'organization',
          sortField: 'organization',
          filter: true,
        },
        { title: this.headerLabel('score', this.$t('app.labels.score')), name: 'score', sortField: 'score' },
        {
          title: this.headerLabel('state', this.$t('app.labels.state')),
          name: 'state',
          sortField: 'state',
          filter: true,
        },
        { title: this.headerLabel('title', this.$t('app.labels.title')), name: 'title', sortField: 'title' },
        { title: this.headerLabel('uniq_id', this.$t('app.labels.uniq_id')), name: 'uniq_id', sortField: 'uniq_id' },
        {
          title: this.headerLabel('score_band', this.$t('app.labels.score_band')),
          name: 'score_band',
          sortField: 'score_band',
          filter: !!this.scoreBands.length && !!this.singleModuleName,
        },
      ].map((field) => ({
        ...field,
        titleClass: this.headerTitleClass,
      }));
    }

    get availableIndexOptions(): string[] {
      if (this.singleModuleName) {
        const defaultColumns = ['title', 'state', 'created_at'];
        const options = Object.keys(this.moduleName?.available_index_options || defaultColumns);
        // support (fields) for followed record widgets
        this.indexOptions?.includes('module_name') && options.push('module_name');
        this.indexOptions?.includes('followed_at') && options.push('followed_at');
        return options;
      } else {
        return map(BASE_FILTER_OPTIONS, (option, key) => key);
      }
    }

    get currentUserStore() {
      return useCurrentUserStore();
    }

    get defaultOnlyOptions(): OnlyOptions<ModuleRecord> {
      const only: OnlyOptions<ModuleRecord> = [
        'id',
        'user_id',
        'sub_form_completion',
        'confidential',
        'location_id',
        'module_name_id',
        {
          sub_form_completion: [
            {
              sub_form_responses: [
                'id',
                'sub_form_question_code',
                'print_value',
                'response',
                'sub_form_question_id',
                'sub_form_question_field_type',
              ],
            },
          ],
        },
      ];

      const onlyMap: Dictionary<OnlyOption<ModuleRecord>> = {
        uniq_id: 'uniq_id',
        title: 'title',
        created_at: 'created_at',
        created_by: { user: ['id', 'full_name'] },
        location: { location: ['id', 'name'] },
        organization: { organization: ['id', 'name'] },
        state: { workflow: ['id', 'color', 'name'] },
        indicator: { indicators: ['id', 'indicator_set_id', 'name', 'color', 'index'] },
        involvement: { user_involvements: ['id', 'user_id', 'involvement_id', 'created_at', { user: ['id', 'full_name'] }] },
        calculation: 'calculations',
        score: 'score',
        score_band: 'score_band',
      };

      const extraFields = ['involvement', 'indicator', 'calculation'];

      const relatedRecordCodes: string[] = [];

      this.visibleIndexOptions.forEach((c) => {
        const extraField = c.split('_')[0];
        extraFields.indexOf(extraField) > -1 && only.push(onlyMap[extraField]);
        onlyMap[c] && only.push(onlyMap[c]);
        c.startsWith(RELATED_RECORDS_PREFIX) && relatedRecordCodes.push(c.substring(RELATED_RECORDS_PREFIX.length));
      });

      this.selectedRelatedMFRQuestionCodeColumns.length &&
        only.push({ related_records: this.selectedRelatedMFRQuestionCodeColumns } as OnlyOption<ModuleRecord>);

      this.visibleIndexOptions.some((option) => option === 'follow') && only.push('follow');

      this.visibleIndexOptions.some((option) => option === 'follow_count') && only.push('follow_count');

      this.visibleIndexOptions.some((option) => option === 'module_name') && only.push({ module_name: ['plural_display'] });

      this.visibleIndexOptions.some((option) => option === 'followed_at') && only.push('followed_at');

      this.showSecondaryInformation && only.push('secondary_information');

      return uniqWith(only, isEqual);
    }

    get fields(): ListManagerField<ModuleRecord>[] {
      let fields = this.visibleIndexOptions
        .map((column) => {
          const predefined = this.availableColumns.find((c) => c.name == column);
          if (predefined) {
            return predefined;
          } else {
            if (column.startsWith(RELATED_RECORDS_PREFIX)) {
              const relatedQuestionCode = column.substring(RELATED_RECORDS_PREFIX.length);
              return {
                title: () => {
                  const module = this.relatedModuleByQuestionCode(relatedQuestionCode);
                  return this.headerLabel(column, module?.plural_display ? `Related ${module.plural_display}` : '');
                },
                filter: true,
                name: 'related_records',
                relatedQuestionCode,
              };
            }
            if (column.startsWith(RELATIONSHIP_PREFIX)) {
              const relationshipCodeAndDirection = column.substring(RELATIONSHIP_PREFIX.length);
              const [direction, relationshipCode] = relationshipCodeAndDirection.split(':');
              return {
                title: () => {
                  const relationship = this.relationships.find((r) => r.code === relationshipCode);
                  return this.headerLabel(column, relationship ? `Relationship ${relationship.name} [${relationshipCode}]` : '');
                },
                filter: true,
                name: 'relationship',
                relationshipCode,
                direction,
              };
            }
            const parts = column.split('_');
            if (parts[0] == 'indicator' && !isNaN(parseInt(parts[1]))) {
              const indicatorSetId = parseInt(parts[1]);
              const indicatorSet = this.moduleName?.indicator_sets?.find((is) => is.id === indicatorSetId);
              if (indicatorSet?.active) {
                return {
                  title: this.headerLabel(column, indicatorSet.name),
                  filter: true,
                  name: 'indicator',
                  sortField: column,
                  indicatorSetId,
                };
              }
            } else if (parts[0] == 'involvement' && !isNaN(parseInt(parts[1]))) {
              const involvementId = parseInt(parts[1]);
              const involvement = this.moduleName?.involvements?.find((inv) => inv.id === involvementId);
              if (involvement) {
                return {
                  title: this.headerLabel(column, involvement.name),
                  name: 'involvement',
                  filter: true,
                  sortField: column,
                  involvementId,
                };
              }
            } else if (parts[0] == 'calculation' && !isNaN(parseInt(parts[1]))) {
              const calculationId = parseInt(parts[1]);
              const calculation = this.moduleName?.record_calculations?.find((rc) => rc.id === calculationId);
              if (calculation) {
                return {
                  title: this.headerLabel(column, calculation.name),
                  name: 'calculation',
                  sortField:
                    calculation.permission_check ||
                    (!!calculation.sub_form_list_ids?.length && calculation.calculation_class === CalculationClass.instant) ||
                    calculation.calculation_method === CalculationMethod.formula
                      ? null
                      : column,
                  calculationId,
                };
              }
            } else {
              const questions = this.moduleMainFormQuestions.filter((q) => q.code === column);
              if (questions.length) {
                const question = questions[0];
                const sortField = questions.every(isSortable) ? column : null;
                return {
                  title: this.headerLabel(column, uniq(questions.map(({ title, short_title }) => short_title || title)).join(', ')),
                  name: 'question',
                  sortField,
                  filter: questions.every(isFilterable),
                  question,
                  questions,
                };
              }
            }
          }
        })
        .filter(Boolean)
        .map((field) => {
          const indexOption = (field?.sortField && this.getIndexOption(field.sortField)) || undefined;
          return {
            ...field,
            ...(indexOption?.wrap_text ? { width: WRAP_TEXT_COLUMN_WIDTH } : {}),
            titleClass: [this.headerTitleClass, indexOption?.wrap_text ? 'wrap-column-title' : ''].join(' '),
          };
        }) as ListManagerField<ModuleRecord>[];

      // The first column as a link to a record
      if (this.linkFirstColumn && fields[0]?.name != 'actions') {
        fields[0] = {
          ...fields[0],
          link: (moduleRecord: ModuleRecord): string => `/module_records/${moduleRecord.id}`,
          click: this.linkingColumnClick,
        };
      }

      if (this.extraFields) {
        fields = fields.concat(this.extraFields);
      }

      if (this.disableSorting) {
        fields = fields.map((field) => ({ ...field, sortField: null }));
      }

      if (this.exportPanelVisible) {
        fields = [{ name: BaseTableFieldCheckbox, width: '70px', titleClass: 'center aligned', dataClass: 'center aligned' }, ...fields];
      }

      return fields;
    }

    get followFilterOptions(): [string, string][] {
      return [
        ['true', this.$t('tenant.shared.follow_and_events.followed').toString()],
        ['false', this.$t('tenant.shared.follow_and_events.not_followed').toString()],
      ];
    }

    get moduleMainFormQuestions(): SubFormQuestion[] {
      return (
        (this.moduleName &&
          orderBy(
            flatten(this.moduleName.main_form?.sub_form_sections?.map((s) => s?.sub_form_questions?.filter((q) => q.active) || []) || []),
            'active',
            'desc'
          )) ||
        []
      );
    }

    get moduleNameFollowIcons() {
      return this.moduleNames.reduce((acc, moduleName) => {
        return {
          ...acc,
          [moduleName.id]: { active: moduleName.show_options?.active_follow_icon, default: moduleName.show_options?.follow_icon },
        };
      }, {} as Record<string, Maybe<string>>);
    }

    get moduleNameKeysOptions(): [number, string][] {
      return this.moduleNames.map((v) => {
        return [v.id, v.plural_display];
      });
    }

    get moduleNamesIds() {
      return map(this.moduleNames, (v) => v.id);
    }

    get questionCodeOptions() {
      const groupedQuestionsByCode = groupBy(this.moduleMainFormQuestions.filter(isSelect), 'code');

      return Object.keys(groupedQuestionsByCode).reduce((memo, code) => {
        if (code) memo[code] = this.getQuestionCodeOptions(groupedQuestionsByCode[code]);
        return memo;
      }, {} as Record<string, SelectOption[]>);
    }

    get relatedQuestionFiltersByCode(): Dictionary<DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>> {
      return this.relatedQuestions.reduce((memo, question) => {
        const module = this.relatedModules.find((m) => m.sub_form_id === question?.sub_form_section?.sub_form_id);
        return module ? { ...memo, [question.code]: { module_name_id: module?.id } } : memo;
      }, {});
    }

    get relationshipCodesToRequest(): { from: string[]; to: string[] } {
      return this.visibleIndexOptions
        .filter((c) => c.startsWith(RELATIONSHIP_PREFIX))
        .reduce(
          (memo, c) => {
            const [direction, code] = c.substring(RELATIONSHIP_PREFIX.length).split(':');
            return { ...memo, [direction]: [...memo[direction as 'from' | 'to'], code] };
          },
          { from: [], to: [] }
        );
    }

    get relationshipFiltersByCode(): Dictionary<DonesafeFilterOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>> {
      return this.relationships.reduce((memo, relationship) => {
        const [direction, source] = relationship.from_module === this.moduleName?.name ? ['from', 'to_module'] : ['to', 'from_module'];
        return { ...memo, [`${direction}:${relationship.code}`]: { module_name: { name: relationship[source as keyof Relationship] } } };
      }, {});
    }

    get selectedRelatedMFRQuestionCodeColumns(): string[] {
      return this.visibleIndexOptions.reduce<string[]>((memo, c) => {
        return c.startsWith(RELATED_RECORDS_PREFIX) ? [...memo, c.substring(RELATED_RECORDS_PREFIX.length)] : memo;
      }, []);
    }

    get selectedRelationshipColumnCodes(): string[] {
      return [...this.relationshipCodesToRequest.from, ...this.relationshipCodesToRequest.to];
    }

    get showSecondaryInformation(): boolean {
      // TODO: fix endpoint to support multiple moduleNames
      return (
        (this.visibleIndexOptions.some((option) => option === 'title') &&
          this.moduleNames.some((m) => {
            const indexOption = this.getIndexOption('title', m);
            if (indexOption?.show_secondary_information) {
              return true;
            }
          })) ||
        false
      );
    }

    get singleModuleName() {
      return (this.moduleNames.length === 1 && this.moduleNames[0]) || null;
    }

    get sortOrder(): ListManagerSortItem[] {
      const column = (this.sort && this.fields.find((f) => f.sortField === this.sort)) || undefined;
      const direction = this.reverse ? 'desc' : 'asc';
      if (column?.sortField && typeof column?.name === 'string') {
        return [{ sortField: column.sortField, field: column.name, direction }];
      }

      if (this.sort) {
        return [{ sortField: this.sort, field: this.sort, direction }];
      }

      return [{ sortField: 'id', field: 'id', direction: 'desc' }];
    }

    get visibleIndexOptions(): string[] {
      const availableConfiguredOptions = intersection(this.indexOptions, this.availableIndexOptions);
      return availableConfiguredOptions.length ? availableConfiguredOptions : this.availableIndexOptions;
    }

    @Watch('fields')
    onFieldsChanged(val: ListManagerField<ModuleRecord>[]) {
      this.$nextTick(() => {
        if (this.manager) this.manager.fields = val;
      });
    }

    @Watch('allFilters', { deep: true })
    onFiltersUpdate(): void {
      if (this.manager) {
        this.manager.filters = { ...this.filters };
        this.manager.customFilters = { ...this.customFilters };
      }
    }

    @Watch('sort')
    onSortUpdate(): void {
      if (this.manager) {
        this.manager.sortOrder = this.sortOrder;
      }
    }

    // TODO: UI builder for other type of links
    actionLinks(rowData: ModuleRecord): RecordActionLink[] {
      const baseLink = `/module_records/${rowData.id}`;
      const targetQuickViewLink = this.quickViewLink(rowData.id);
      const links: RecordActionLink[] = [];

      if (this.permissions[rowData.id] && this.permissions[rowData.id].edit_access) {
        const editUrl = `${baseLink}/edit`;
        links.push({
          label: this.$t('module_names.record_link_behaviour.quick_edit'),
          url: editUrl, // allow right click open new tab with full edit page
          click: (event: MouseEvent) =>
            this.openModalOrLink({
              modal: true,
              link: editUrl,
              event,
              modalProps: {
                mode: 'module-record-edit',
                recordId: rowData.id,
                title: this.moduleRecordEditTitle(),
              },
            }),
          modal: true,
        });
      }
      links.push({
        label: this.$t('module_names.record_link_behaviour.quick_view'),
        url: baseLink,
        click: (event: MouseEvent) =>
          this.openModalOrLink({
            modal: true,
            link: targetQuickViewLink,
            event,
            modalProps: {
              mode: 'module-record-show',
              recordId: rowData.id,
              title: rowData.title || this.$t('tenant.sub_form_completions.show.form_completion'),
            },
          }),
        modal: true,
      });
      links.push({
        label: this.$t('module_names.record_link_behaviour.go_to_record'),
        url: baseLink,
        click: (event: MouseEvent): Promise<void | boolean> =>
          this.openModalOrLink({
            modal: false,
            link: baseLink,
            event,
          }),
        modal: false,
      });
      links.push({
        label: this.$t('module_names.copy_link_modal.link_qr_code'),
        click: (event: MouseEvent) => this.showQrCodeLinkModal(event, rowData.id),
        modal: true,
      });

      return links;
    }

    applyAccessibleFilters(field: ListManagerField<ModuleRecord>, filters: UserFilter): UserFilter {
      const accessibleFilters: UserFilter = {};

      if (field?.sortField) {
        this.moduleNames.some((m) => {
          const indexOption = (field?.sortField && this.getIndexOption(field.sortField, m)) || undefined;
          if (indexOption?.restrict_user_selector_to_users_have_access_to_active_user) {
            accessibleFilters.accessible_location_id = this.currentUserStore.data?.home_location_id;
            accessibleFilters.accessible_organization_id = this.currentUserStore.data?.home_organization_id;
            return;
          }
        });
      }

      return { ...filters, ...accessibleFilters };
    }

    calculationResult(recordId: number, calculationId: number): string | number | null {
      const recordCalculations = this.calculationsByRecordId[recordId];

      if (!recordCalculations) return null;

      return recordCalculations[calculationId] || this.$t('app.labels.na');
    }

    debounceFollow(record: ModuleRecord): void {
      if (!this.debounceFollowHash[record.id]) {
        this.debounceFollowHash[record.id] = debounce(() => this.updateFollow(record), 1000);
      }
      this.debounceFollowHash[record.id]();
    }

    fetchData(): void {
      if (this.singleModuleName) {
        this.fetchModuleName(this.singleModuleName.id).finally(() => {
          this.initializeScoreBands();
          this.initializeManager();
        });
      } else {
        this.initializeScoreBands();
        this.initializeManager();
      }
    }

    fetchModuleName(id: string | number): AxiosPromise<ModuleName> {
      return this.$api.getModuleName(id as number, { only: MRT_MN_ONLY }, { cache: true }).then((moduleNameResponse) => {
        this.moduleName = moduleNameResponse.data;
        const promises = [];
        if (this.selectedRelationshipColumnCodes.length) {
          promises.push(
            this.$api
              .getRelationships(
                { filters: { code: this.selectedRelationshipColumnCodes }, per_page: this.selectedRelationshipColumnCodes.length },
                { cache: true }
              )
              .then(({ data }) => {
                this.relationships = data;
              })
          );
        }
        if (this.selectedRelatedMFRQuestionCodeColumns.length) {
          promises.push(
            this.$api
              .getSubFormQuestions(
                {
                  filters: {
                    code: this.selectedRelatedMFRQuestionCodeColumns,
                    field_type: FieldType.main_form_relation,
                    config: { main_form_id: this.moduleName.id },
                  },
                  include: ['sub_form_section'],
                },
                { cache: true }
              )
              .then(({ data }) => {
                this.relatedQuestions = data;
                const subFormIds = uniq(this.relatedQuestions.map((q) => q.sub_form_section?.sub_form_id).filter((x) => x));
                if (subFormIds.length) {
                  return this.$api
                    .getModuleNames(
                      {
                        per_page: -1,
                        only: ['id', 'sub_form_id', 'name', 'display', 'plural_display'],
                        filters: { sub_form_id: subFormIds, active: true },
                      },
                      { cache: true }
                    )
                    .then(({ data }) => {
                      this.relatedModules = data;
                    });
                }
              })
          );
        }

        return Promise.all(promises).then(() => moduleNameResponse);
      });
    }

    fetchRecordPermissions(record: ModuleRecord): Promise<RecordAccess> {
      return this.permissions[record.id]
        ? Promise.resolve(this.permissions[record.id])
        : this.$api.getModuleRecord(record.id, { only: ['permissions'] }, { cache: false }).then(({ data }) => {
            this.permissions = { ...this.permissions, [record.id]: data.permissions };
            return this.permissions[record.id] as RecordAccess;
          });
    }

    followClick(record: ModuleRecord): void {
      if (this.manager) {
        const follow = !record.follow;
        this.table?.setData(this.manager.items.map((r) => (record.id === r.id ? { ...r, follow } : r)));
        this.debounceFollow(record);
      }
    }

    getIndexOption(key: string, moduleName?: Nullable<LimitedModuleName>): Maybe<ModuleNameIndexOption> {
      return (moduleName || this.moduleName)?.index_options?.find((option) => option.key === key);
    }

    getInvolvementFilter(field: ListManagerField<ModuleRecord> & { involvementId: number }): UserFilter {
      return this.applyAccessibleFilters(field, { user_involvements: { involvement_id: field.involvementId } });
    }

    getManager(): ListManager<ModuleRecord, DonesafeModuleRecordExtraFilters & { _do_not_fetch?: boolean }> {
      return new ListManager<ModuleRecord, DonesafeModuleRecordExtraFilters & { _do_not_fetch?: boolean }>({
        fetchDataFunction: this.managerFetchDataFunction,
        afterFetch: this.managerAfterFetchFunction,
        useHistory: this.useHistory,
        filters: this.filters,
        customFilters: this.customFilters,
        sortOrder: this.sortOrder,
        rowClass: this.rowClass,
        per_page: this.pageSize,
        fields: this.fields,
        allowFilters: !!this.allowCustomFilters,
      });
    }

    getModuleNameLinkBehaviour(module_name_id: number) {
      const moduleName = this.moduleNames?.find((m) => m.id == module_name_id) as Maybe<ModuleName>;
      return moduleName?.record_link_behaviour;
    }

    getQuestionCodeOptions(questions: SubFormQuestion[]): SelectOption[] {
      const options = questions.reduce((memo, question) => memo.concat(Object.values(question.options.values || {})), [] as SelectOption[]);
      const groupedOptions = this.groupByKeyUnsorted(options);

      return Object.keys(groupedOptions).reduce((memo, key) => {
        const values = groupedOptions[key];
        const value = uniq(values.map((v) => v.value.trim())).join(', ');
        return [...memo, { key, value }];
      }, [] as SelectOption[]);
    }

    getUserFilter(
      field: ListManagerField<ModuleRecord> & {
        question?: SubFormQuestion<MultiPersonSelectorQuestionOptions | SinglePersonSelectorQuestionOptions>;
      }
    ): UserFilter {
      // field.question.config.person_type only exists now for multi_person_selector
      // use field.question.config.filters with `type` key for single_person_selector
      // if field.question.config.person_type exists, it means it's a multi_person_selector question
      // otherwise it's single_person_selector
      const personType = (field.question?.config as MultiPersonSelectorQuestionOptions)?.person_type;
      const type = (personType !== 'All' && personType) || field.question?.config.filters?.find((f) => f.key === 'type')?.value;
      const filters = this.applyAccessibleFilters(field, field.question ? { type } : {});
      if (this.accountStore.data.hide_inactive_olu_for_filters) {
        return { ...filters, active: true };
      }
      return filters;
    }

    groupByKeyUnsorted(items: SelectOption[]): Dictionary<SelectOption[]> {
      return items.reduce((memo, item) => {
        if (memo[item.key]) {
          memo[item.key].push(item);
        } else {
          memo[item.key] = [item];
        }
        return memo;
      }, {} as Dictionary<SelectOption[]>);
    }

    headerLabel(fieldName: string, defaultLabel = ''): string {
      const indexOption = this.moduleName?.index_options?.find((x) => x.key === fieldName);
      const indexOptionLabel = this.moduleName?.index_option_labels?.[fieldName];

      return indexOption && indexOption.hide_label ? '' : indexOptionLabel || defaultLabel;
    }

    indicatorSetIndicators(indicators: Indicator[], setId: number): Indicator[] {
      return orderBy(
        indicators.filter((i) => i.indicator_set_id === setId),
        'index'
      );
    }

    initializeManager(): void {
      this.manager = this.getManager();
      this.$emit('init');
      if (this.subscribeIndexNotifications) {
        this.subscribeToIndexNotifications();
      } else if (this.subscribe) {
        this.subscribeToUpdates();
      }
    }

    initializeModuleNames(): AxiosPromise<ModuleName[]> {
      if (!this.allFilters.module_name_id) {
        return Promise.reject({ data: { error: 'Module ID is not set' } });
      }
      return this.$api.getModuleNames(
        {
          filters: { id: this.allFilters.module_name_id },
          only: ['id', 'plural_display', 'record_link_behaviour', 'show_options', 'score_band_profile_id', 'index_options', 'feature_set'],
        },
        { cache: true }
      );
    }

    initializeScoreBands(): void {
      if (!this.moduleNames.length || !this.visibleIndexOptions.some((option) => option === 'score_band')) {
        // won't fetch if there isn't any module
        // or if score_band is not in visibleIndexOptions
        return;
      }
      this.$api
        .getScoreBandProfiles(
          {
            filters: { id: this.moduleNames.map((v) => v.score_band_profile_id) },
            only: ['score_bands', 'no_band_score_band'],
          },
          { cache: true }
        )
        .then(({ data }) => {
          let scoreBands: ScoreBand[] = [];
          data.map((v) => {
            if (v.score_bands) {
              scoreBands = [...scoreBands, ...v.score_bands];
            }
            if (v.no_band_score_band) {
              scoreBands = [...scoreBands, v.no_band_score_band];
            }
          });
          this.scoreBands = scoreBands;
        });
    }

    involvementNames(userInvolvements: UserInvolvement[], involvementId: number): string {
      return orderBy(
        userInvolvements.filter((i) => i.involvement_id === involvementId),
        'created_at'
      )
        .map((i) => i.user?.full_name)
        .filter(Boolean)
        .join(', ');
    }

    isDateDatetime(question: SubFormQuestion): boolean {
      return [FieldType.date, FieldType.datetime].indexOf(question.field_type) > -1;
    }

    isMfrMmfr(question: SubFormQuestion): boolean {
      return [FieldType.main_form_relation, FieldType.multi_main_form_relation].indexOf(question.field_type) > -1;
    }

    isPersonSelector(question: SubFormQuestion): boolean {
      return [FieldType.single_person_selector, FieldType.multi_person_selector].indexOf(question.field_type) > -1;
    }

    linkingColumnClick(rowData: ModuleRecord, event: MouseEvent): void {
      const recordLinkBehaviour =
        this.recordLinkBehaviour || this.getModuleNameLinkBehaviour(rowData.module_name_id) || RecordLinkBehaviour.GoToRecord;

      const baseLink = `/module_records/${rowData.id}`;
      const quickEditLink = `${baseLink}/edit`;

      if (recordLinkBehaviour === RecordLinkBehaviour.GoToRecord) {
        this.openModalOrLink({
          modal: false,
          link: baseLink,
          event,
        });
      } else if (recordLinkBehaviour === RecordLinkBehaviour.QuickEdit) {
        this.openModalOrLink({
          modal: true,
          link: quickEditLink,
          event,
          modalProps: {
            mode: 'module-record-edit',
            recordId: rowData.id,
            title: this.moduleRecordEditTitle(),
          },
        });
      } else if (recordLinkBehaviour === RecordLinkBehaviour.QuickView) {
        this.openModalOrLink({
          modal: true,
          link: this.quickViewLink(rowData.id),
          event,
          modalProps: {
            mode: 'module-record-show',
            recordId: rowData.id,
            title: rowData.title || this.$t('tenant.sub_form_completions.show.form_completion'),
          },
        });
      }
    }

    async loadCalculations(recordIds: number[]): Promise<void> {
      if (!recordIds.length) return;

      const { module_name_id } = this.allFilters; // for performance reasons

      const calculationsOnly = {
        filters: { id: recordIds, module_name_id },
        only: [...([this.apiOptions.only].flat() || []).filter((v) => v === 'calculations' || v === 'id')] as OnlyOptions<ModuleRecord>,
      };

      // no calculations in a table
      if (Array.isArray(calculationsOnly.only) && calculationsOnly.only.length < 2) return;

      const { data } = await this.$api.getModuleRecords(calculationsOnly, { cache: true });

      this.calculationsByRecordId = mapValues(
        keyBy(data, ({ id }) => id),
        ({ calculations }) => calculations || {}
      );
    }

    locationFilters(key: string): DonesafeFilterOptions<Location, { with_restrictions?: boolean }> {
      let filters = this.accountStore.data.hide_inactive_olu_for_filters ? { active: true } : {};
      let with_restrictions = false;

      if (this.accountStore.data.limit_permissions_by_explicit_locations || this.accountStore.data.limit_permissions_by_location) {
        this.moduleNames.some((m) => {
          const option = this.getIndexOption(key, m);
          if (option?.with_restrictions) {
            with_restrictions = true;
            return;
          }
        });
      }
      return { ...filters, with_restrictions };
    }

    managerAfterFetchFunction(data: ModuleRecord[]): void {
      this.$emit('fetch', data);
      if (this.singleModuleName) {
        this.loadCalculations(data.map(({ id }) => id));
        this.preloadRelationshipRecords(data);
      }
    }

    managerFetchDataFunction(
      params: ListManagerFetchDataParams<ModuleRecord, DonesafeModuleRecordExtraFilters & { _do_not_fetch?: boolean }>
    ): AxiosPromise<ModuleRecord[]> {
      if (!params.filters?.module_name_id || this.allFilters._do_not_fetch) {
        return Promise.resolve({ data: [], headers: { total: 0 } }) as unknown as AxiosPromise<ModuleRecord[]>;
      } else {
        this.permissions = {};
        // TODO: TODO: TODO: TODO: TODO: TODO: TODO: DELETE FILTERS THIS LINE
        const requestOptions = { ...params, ...this.apiOptions };
        const withoutCalculations = {
          ...requestOptions,
          only: [...([requestOptions.only].flat() || []).filter((v) => v !== 'calculations')] as OnlyOptions<ModuleRecord>,
        };
        return this.$api.getModuleRecords(withoutCalculations, { cache: true });
      }
    }

    organizationFilters(key: string): DonesafeFilterOptions<Organization, { with_restrictions?: boolean }> {
      const filters = this.accountStore.data.hide_inactive_olu_for_filters ? { active: true } : {};
      let with_restrictions = false;

      if (this.accountStore.data.limit_permissions_by_organization) {
        this.moduleNames.some((m) => {
          const option = this.getIndexOption(key, m);
          if (option?.with_restrictions) {
            with_restrictions = true;
            return;
          }
        });
      }

      return { ...filters, with_restrictions };
    }

    preloadRelationshipRecords(records: ModuleRecord[]): void {
      const ids = records.map(({ id }) => id);
      if (ids.length) {
        const only: OnlyOptions<RecordRelation> = ['relationship_code', 'from_id', 'to_id'];
        this.relationshipCodesToRequest.from.length &&
          this.$api
            .getRecordRelations(
              { only, filters: { relationship_code: this.relationshipCodesToRequest.from, from_id: ids }, per_page: -1 },
              { cache: true }
            )
            .then(({ data }) => {
              this.fromRecordRelations = data;
            });
        this.relationshipCodesToRequest.to.length &&
          this.$api
            .getRecordRelations(
              { only, filters: { relationship_code: this.relationshipCodesToRequest.to, to_id: ids }, per_page: -1 },
              { cache: true }
            )
            .then(({ data }) => {
              this.toRecordRelations = data;
            });
      }
    }

    quickViewLink(id: number): string {
      return `/module_records/${id}/quick_view`;
    }

    recordIdsForRelationship(sourceRecordId: number, direction: 'from' | 'to', relationshipCode: string): number[] {
      if (direction === 'from') {
        return this.fromRecordRelations
          .filter(({ relationship_code, from_id }) => relationship_code === relationshipCode && from_id === sourceRecordId)
          .map(({ to_id }) => to_id);
      } else {
        return this.toRecordRelations
          .filter(({ relationship_code, to_id }) => relationship_code === relationshipCode && to_id === sourceRecordId)
          .map(({ from_id }) => from_id);
      }
    }

    recordSelectorFilters(questions: SubFormQuestion[]): DonesafeFilterOptions<ModuleRecord> {
      return {
        module_name_id: questions.filter((q) => this.isMfrMmfr(q)).map((q) => q.config.main_form_id),
      };
    }

    recordSelectorOnly(question: SubFormQuestion): OnlyOptions<ModuleRecord> {
      const indexOption = this.moduleName?.index_options?.find((x) => x.key === question.code);
      if (indexOption?.custom_secondary_information) {
        return ['id', 'title', { secondary_information: indexOption.secondary_information || [] }];
      }
      return ['id', 'title', 'secondary_information'];
    }

    // TODO: collect all ids and fetch all of them at once
    // TODO: support secondary_information for GET /api/module_records/:id
    refreshSingleRecord(recordId: number): void {
      this.$api.cache.clear();
      this.$api
        .getModuleRecords(
          {
            ...this.apiOptions,
            filters: {
              id: recordId,
              module_name_id: this.moduleName?.id, // this is to make secondary information field work
            },
          },
          { cache: true }
        )
        .then(({ data }) => {
          if (data[0]) {
            this.table?.setData(this.manager?.items.map((item) => (item.id === recordId ? data[0] : item)) || []);
          } else {
            this.refreshTable(true);
          }
        })
        .catch(() => this.refreshTable(true)); // if the record can not be fetched let's refresh the whole table
    }

    refreshTable(debounce = false) {
      this.$api.cache.clear();
      debounce ? this.table?.debounceUpdate() : this.table?.reload();
    }

    relatedModuleByQuestionCode(code: string): ModuleName | undefined {
      const question = this.relatedQuestions.find((q) => q.code === code);
      return question && this.relatedModules.find((m) => m.sub_form_id === question?.sub_form_section?.sub_form_id);
    }

    showQrCodeLinkModal(event: MouseEvent, id: number): Promise<boolean> {
      this.qrCodeText = `${window.location.origin}/module_records/${id}`;
      this.qrCodeTitle = this.$t('module_names.copy_link_modal.module_record', { id });
      this.qrCodeModalVisible = true;

      return Promise.resolve(true);
    }

    subscribeToIndexNotifications(): void {
      this.indexNotificationsSubscription = consumer.subscriptions.create(
        {
          channel: 'ModuleRecordIndexNotificationsChannel',
          module_name_id: this.moduleName?.id,
        },
        {
          received: ({ action }: { action: string }) => {
            if (this.manager && this.table && action === 'refresh') {
              this.refreshTable();
            }
          },
        }
      );
    }

    subscribeToUpdates(): void {
      this.updatesSubscription = consumer.subscriptions.create(
        { channel: 'WebNotificationsChannel', record_type: 'ModuleRecord' },
        {
          received: ({ id }: { id: number }) => {
            const record = this.manager?.items.find((item) => item.id === id);
            if (record) {
              if (this.subscribe === 'update') {
                this.refreshSingleRecord(record.id);
              } else {
                this.refreshTable(true);
              }
            }
          },
        }
      );
    }

    updateFollow(record: ModuleRecord): void {
      if (this.manager) {
        const realItem = this.manager.items.find((r) => r.id === record.id);
        if (realItem) {
          const follow = !!realItem.follow;
          const followParams = { followable_type: 'ModuleRecord', followable_id: record.id };
          const promise = follow ? this.$api.followEntity(followParams) : this.$api.unfollowEntity(followParams);
          promise.then(({ data }) => {
            this.manager &&
              this.table?.setData(this.manager.items.map((r) => (record.id === r.id ? { ...record, follow: data.follow } : r)));
            this.refreshSingleRecord(record.id);
            this.$api.cache.clear();
          });
        }
      }
    }

    beforeMount(): void {
      this.initializeModuleNames().then(({ data }) => {
        this.moduleNames = data;
        this.fetchData();
      });
    }

    beforeDestroy(): void {
      this.indexNotificationsSubscription?.unsubscribe();
      this.updatesSubscription?.unsubscribe();
    }
  }
