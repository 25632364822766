import type { ModuleName } from '@app/models/module-name';
import type { FirstLevelKeyLiterals } from '@app/utils/types/first-level-literals';
import { Tuple } from '@app/utils/types/tuple';

export const MRT_MN_ONLY = Tuple([
  'id',
  'name',
  'index_options',
  'index_option_labels',
  'available_index_options',
  'plural_display',
  'indicator_sets',
  'involvements',
  'record_calculations',
  'record_link_behaviour',
  'score_band_profile_id',
  'score_band_profile',
  'feature_set',
  {
    main_form: [
      {
        sub_form_sections: [
          { sub_form_questions: ['id', 'code', 'active', 'short_title', 'title', 'field_type', 'attachments', 'config', 'options'] },
        ],
      },
    ],
  },
] as const);

export type LimitedModuleName = Pick<ModuleName, FirstLevelKeyLiterals<(typeof MRT_MN_ONLY)[number]>>;

export const AUDIT_REPORTS_ONLY = Tuple(['id', 'name', 'report_format', 'report_type', 'restricted', 'row_limit', 'visibility'] as const);
export type AuditReportTemplateOnly = (typeof AUDIT_REPORTS_ONLY)[number];
